var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("checkout-header", {
        staticClass: "-mb-4",
        attrs: {
          "back-button-action": _vm.isDesktop ? false : _vm.backBtnAction,
        },
        scopedSlots: _vm._u(
          [
            !_vm.isDesktop
              ? {
                  key: "back",
                  fn: function () {
                    return [
                      _vm._v(" " + _vm._s(_vm.$t("checkout.back_init")) + " "),
                    ]
                  },
                  proxy: true,
                }
              : null,
            {
              key: "header",
              fn: function () {
                return [
                  [
                    _c(
                      "span",
                      { attrs: { "data-cy": "choose-wizard_control-text" } },
                      [_vm._v(" 1 " + _vm._s(_vm.$t("checkout.of")) + " 2 — ")]
                    ),
                  ],
                  _c(
                    "strong",
                    {
                      attrs: { "data-cy": "choose-wizard_control_choose-text" },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("checkout.chose_recurrence")))]
                  ),
                ]
              },
              proxy: true,
            },
          ],
          null,
          true
        ),
      }),
      _c("div", { staticClass: "flex flex-wrap" }, [
        _vm.getPlans
          ? _c(
              "div",
              { staticClass: "inner-container" },
              _vm._l(_vm.getPlans, function (plan) {
                return _c(
                  "div",
                  {
                    key: plan.id,
                    on: {
                      click: function ($event) {
                        return _vm.eventGtm(`custom.${plan.name}`)
                      },
                    },
                  },
                  [
                    _c("list-item", {
                      ref: plan.name,
                      refInFor: true,
                      attrs: {
                        value: plan.name,
                        line1: _vm.line1(plan),
                        line2: _vm.line2(
                          plan.recurrencePeriod,
                          plan.price,
                          plan.corrency
                        ),
                        "data-cy": `choose-${plan.recurrencePeriod}-list`,
                        selected: _vm.selectedPlan === plan.recurrencePeriod,
                      },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.setPlan(plan)
                        },
                      },
                    }),
                  ],
                  1
                )
              }),
              0
            )
          : _vm._e(),
      ]),
      _c(
        "div",
        { staticClass: "advance-btn justify-center align-middle" },
        [
          _c("submit-button-default", {
            staticStyle: { height: "73px", "max-width": "100%" },
            attrs: {
              text: _vm.$t("advance"),
              "data-cy": "checkout-submit1-btn",
              disabled: !_vm.isPlanSelected,
              loading: _vm.loading,
            },
            nativeOn: {
              click: function ($event) {
                _vm.nextStep()
                _vm.eventGtm("custom.nextPlan")
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }