<template>
    <div>
        <checkout-header :back-button-action="isDesktop ? false : backBtnAction" class="-mb-4">
            <template v-if="!isDesktop" v-slot:back> {{ $t("checkout.back_init") }} </template>
            <template v-slot:header>
                <template>
                    <span data-cy="choose-wizard_control-text">
                        1 {{ $t("checkout.of") }} 2 &mdash;
                    </span>
                </template>
                <strong data-cy="choose-wizard_control_choose-text">
                    {{ $t("checkout.chose_recurrence") }}</strong
                >
            </template>
        </checkout-header>
        <div class="flex flex-wrap">
            <div v-if="getPlans" class="inner-container">
                <div v-for="plan in getPlans" :key="plan.id" @click="eventGtm(`custom.${plan.name}`)">
                    <list-item
                        :value="plan.name"
                        :line1="line1(plan)"
                        :line2="line2(plan.recurrencePeriod, plan.price, plan.corrency)"
                        :data-cy="`choose-${plan.recurrencePeriod}-list`"
                        @click.native="setPlan(plan)"
                        :selected="selectedPlan === plan.recurrencePeriod"
                        :ref="plan.name"
                    />
                </div>
            </div>
        </div>
        <div class="advance-btn justify-center align-middle">
            <submit-button-default
                style="height: 73px; max-width: 100%;"
                :text="$t('advance')"
                data-cy="checkout-submit1-btn"
                :disabled="!isPlanSelected"
                @click.native="nextStep(); eventGtm('custom.nextPlan')"
                :loading="loading"
            />
        </div>
    </div>
</template>

<script>
import {
    HOME_VIEW,
    PLAN_SELECTOR_VIEW,
    PAYMENT_METHOD_SELECTOR_VIEW,
} from "@/router/constants";
import {
    CHECKOUT_MODULE,
    CHANGE_PLAN,
    GET_PLAN,
    GET_PLANS_MONTHLY,
    GET_PLANS,
    CHANGE_PLAN_SELECTED_RECURRENCIES,
    CHANGE_CHOSEN_PLAN,
} from "../store/constants/checkout";
import { mapGetters, mapActions } from "vuex";
import CheckoutHeader from "@/components/Checkout/Header.vue";
import ListItem from "@/components/Checkout/ListItem.vue";
import SubmitButtonDefault from "@/components/SubmitButtonDefault.vue";
import { defaultPlansNames, Currency } from "@/constants";
import Utils from "../utils/Utils";

export default {
    name: PLAN_SELECTOR_VIEW,
    components: {
        CheckoutHeader,
        ListItem,
        SubmitButtonDefault,
    },
    data() {
        return {
            selectedPlan: null,
            openModalWarningFiscal: false,
            loading: false,
        };
    },
    computed: {
        ...mapGetters({
            getPlan: `${CHECKOUT_MODULE}/${GET_PLAN}`,
            getPlans: `${CHECKOUT_MODULE}/${GET_PLANS_MONTHLY}`,
            getAllPlans: `${CHECKOUT_MODULE}/${GET_PLANS}`,
            lang: `language`,
            isDesktop: `isDesktop`,
            eventGtm: 'eventGtm',
        }),
        isPlanSelected() {
            if (this.getPlan?.name === defaultPlansNames.FISCAL) {
               return false
            } else {
                return this.getPlan?.recurrencePeriod;
            }
        },
    },
    async mounted() {
        this.selectedPlan = this.getPlan?.recurrencePeriod;
    },
    async created() {
        this.loading = true;
        if (!this.getPlans) {
            try {
                const priceId = this.$store.getters['store/store'].store.priceId
                await this.getPlansAction({
                    priceId: priceId
                });
                this.getAllPlansAction({
                    priceId: priceId
                });
            }
            catch(error) {
                const text = this.$t(
                "checkout.error"
                ) + " " + this.$t(
                "checkout.suport"
                )
                this.errorHandling(error, text);
            }
        }
        this.loading = false;
    },
    watch: {
        selectedPlan(newPlan) {
            if (newPlan === defaultPlansNames.FISCAL) {
                this.openModalWarningFiscal = true;
            }
        },
    },
    methods: {
        ...mapActions({
            changePlanAction: `${CHECKOUT_MODULE}/${CHANGE_PLAN}`,
            changePlanRecurrencies: `${CHECKOUT_MODULE}/${CHANGE_PLAN_SELECTED_RECURRENCIES}`,
            getPlansAction: `${CHECKOUT_MODULE}/${GET_PLANS_MONTHLY}`,
            getAllPlansAction: `${CHECKOUT_MODULE}/${GET_PLANS}`,
            changeChosenPlanAction: `${CHECKOUT_MODULE}/${CHANGE_CHOSEN_PLAN}`
        }),
        backBtnAction() {
            return this.$router.push({
                name: HOME_VIEW,
            });
        },
        getDefaultPlanName(plan) {
            const planDefault = this.$t("plan_default_name")[plan];
            if (!planDefault) {
                return plan;
            }
            return planDefault;
        },

        async setRecurrencies() {
            await this.changePlanRecurrencies(this.getAllPlans.reverse());
        },
        nextStep() {
            this.changeChosenPlanAction(this.getPlan);
            return this.$router.push({
                name: PAYMENT_METHOD_SELECTOR_VIEW,
            });
        },
        line1(recurrence) {
            return `<strong>${
                this.$t("recurrences_num")[recurrence.recurrencePeriod]
            }</strong> &mdash; ${Currency[recurrence.corrency]}${
                recurrence.price
            }  <small class='text-cool-gray-500'>${
                this.$t("recurrences_freq_num")[recurrence.recurrencePeriod]
            }</small>`;
        },
        line2(recurrence, price, currency) {
            const discount = Utils.calcValuePerMounth(recurrence, price);
            if (parseInt(discount) === parseInt(price)) {
                return `<em>${this.$t("checkout.no_discount")}</em>`;
            }
            return `<em>${this.$t("checkout.pre_discount")} ${Currency[currency]} ${discount} /${this.$t("month")}</em>`;
        },
        setPlan(plan) {
            this.changePlanAction(plan);
            this.selectedPlan = plan.recurrencePeriod;
        },
        errorHandling(error, message) {
            this.$fire({
                text: message,
                type: "error",
                timer: 6000,
                customClass: "alert-error-1",
            }).then((result) => {
                 if (result.isConfirmed) {
                    window.location.reload();
                }
                setInterval(window.location.reload(), 6000);
            });
            throw new Error(JSON.stringify(error));
        },
    },
};
</script>

<style lang="scss">
.submit-label {
    color: blue;
    text-decoration: underline;
    cursor: pointer;
}
.advance-btn {
    width: 100%;
    max-width: 512px;
    margin-left: auto;
    margin-right: auto;
}
</style>
